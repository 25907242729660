/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from 'react-helmet'
// import Form from '../components/Form';
import Footer from '../components/Footer'
import "./index.css"
import '../scss/base.scss'
import '../scss/content.scss'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          { name: 'description', content: 'Pups N Suds Dog Grooming in Puslinch Ontario Near Guelph, Kitchener, Burlington, Oakville, Hamilton - Voted #1 Dog Groomer 2017, 2018' },
          { name: 'keywords', content: 'sample, something' },
          { name: 'msapplication-TileColor', content: '#da532c' },
          { name: 'theme-color', content: '#a3ddfb' },
        ]}
        link={[
          { rel: 'home', href: 'pupsnsuds.ca' },
          { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Sanchez:400,400i' },
          { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
          { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
          { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
          { rel: 'manifest', href: '/site.webmanifest' },
        ]}      
        script={[
          { src: 'https://use.fontawesome.com/releases/v5.0.4/js/all.js' }
        ]}  
      />
      <main>{children}</main>

      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout;