import React from 'react';
import { Link } from 'gatsby';
import Menu from './Menu';
import '../scss/header.scss';
import '../scss/fonts.scss';
import '../scss/nav.scss';

const HeaderSmall = ({ siteTitle }) => (
  <header className="header--small">
    <div className="header--small__copy">
      <h1 className="header--small__h1">
        <Link to="/" className="white">
          {siteTitle}
        </Link>
      </h1>
    </div>

    <Menu />
  </header>
);

export default HeaderSmall;
