import React from 'react';
import { Link } from 'gatsby';
import '../scss/header.scss';
import '../scss/fonts.scss';
import '../scss/nav.scss';

function menuToggle(e) {
  document.querySelector('#menu-toggle').classList.toggle('open');
  document.querySelector('.nav__list').classList.toggle('show');
};

const Menu = () => (
  <div className="menu">
    <div className="header__cta">
      <a href="tel:+19053209050" className="header__phone">
        <span className="header__phone__span">Call Us</span>
        <h3 className="header__phone__num">905-320-9050</h3>
        <h3 className="header__phone__num--mobile"><i className="fas fa-phone"></i></h3>
      </a>
      <button className="header__phone__menu" onClick={menuToggle}>
        <span>Menu</span>
        <div id="menu-toggle">
          <div id="hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div id="cross">
            <span></span>
            <span></span>
          </div>
        </div>
      </button>
    </div>
    <nav id="menu" className="nav">
      <ul className="nav__list">
        <li className="nav__list-item"><Link to="/" onClick={menuToggle}>Home</Link></li>
        <li className="nav__list-item"><Link to="/#about" onClick={menuToggle}>About Us</Link></li>
        <li className="nav__list-item"><Link to="/#location" onClick={menuToggle}>Location &amp; Hours</Link></li>
        <li className="nav__list-item"><Link to="/rates" onClick={menuToggle}>Rates</Link></li>
        <li className="nav__list-item"><Link to="/rates/#services" onClick={menuToggle}>Our Services</Link></li>
        <li className="nav__list-item"><Link to="/gallery" onClick={menuToggle}>Gallery</Link></li>
      </ul>
    </nav>
  </div>
);

export default Menu;
