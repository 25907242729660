import React from 'react';
import { Link } from "gatsby"
import styles from './footer.module.css';

const Footer = () => {
    return (
        <div id="footer" className={styles.footer}>
            <div className={styles.footer_container}>
                <nav id="footer-menu" className={styles.footerNav}>
                    <ul className={styles.footer_list}>
                        <li className={styles.footer_list_item}><Link className={styles.link} to="/">Home</Link></li>
                        <li className={styles.footer_list_item}><Link className={styles.link} to="/#about">About Us</Link></li>
                        <li className={styles.footer_list_item}><Link className={styles.link} to="/#location">Location &amp; Hours</Link></li>
                        <li className={styles.footer_list_item}><Link className={styles.link} to="/rates/#rates">Rates</Link></li>
                        <li className={styles.footer_list_item}><Link className={styles.link} to="/rates/#services">Our Services</Link></li>
                        <li className={styles.footer_list_item}><Link className={styles.link} to="/gallery">Gallery</Link></li>
                    </ul>
                </nav>
                <div className={styles.social}>
                    <p className={styles.social__p}>Check us out on Facebook</p> 
                    <a className={styles.fbLink} href="https://www.facebook.com/Pups-N-Suds-539818302789326/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook"></i>
                    </a>
                </div>
                <div className={styles.copyright}>
                    <p className={styles.copyright_p}>&copy; {new Date().getFullYear()} Pups N Suds Dog Grooming. All Rights Reserved. <a className={styles.link} target="_blank" href="https://mrtylersmith.com" rel="noopener noreferrer">Website by T.Smith</a></p>
                </div>
            </div>
        </div>
    );
}

export default Footer;